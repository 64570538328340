import {Constants} from "../constants/constants";

export const initialState = {
    message: null
};

export default function message(state = initialState, action) {
    switch (action.type) {
        case Constants.MESSAGE_SHOW:
            return { ...state, message: action.message }
        case Constants.MESSAGE_ANSWER:
            return { ...state, message: null }

        default:
            return state
    }
}
