import {Constants} from "../constants/constants";

export function logout() {
    return { type: Constants.AUTH_LOGOUT }
}
export function login(token) {
    return { type: Constants.AUTH_LOGIN, token }
}
export function setUser(user) {
    return { type: Constants.AUTH_SET_USER, user }
}