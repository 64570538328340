import {unregister} from "../../../serviceWorker";

const DeleteCache = () => {
    unregister();
    setTimeout(() => {
        window.location.href = "/";
    }, 1000);


};

export default DeleteCache;