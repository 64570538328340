import React, {Component} from 'react';
import {BrowserRouter, Router, Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import './App.scss';
import { connect } from 'react-redux'
import {Loading} from "./views/General/Loading";
import {retry} from "./helpers/helper";
import DeleteCache from "./views/Pages/Cache/DeleteCache";
import history from './helpers/history';


const loading = () => <Loading/>;

// Containers
const DefaultLayout = React.lazy(() => retry( () => import('./containers/DefaultLayout')));

// Pages
const ForceLogin = React.lazy(() => retry( () => import('./views/Pages/Login/ForceLogin')));
const Login = React.lazy(() => retry( () => import('./views/Pages/Login/Login')));
const ForgotPassword = React.lazy(() => retry(() => import('./views/Pages/Login/ForgotPassword')));
const ForgotPasswordConfirm = React.lazy(() => retry(() => import('./views/Pages/Login/ForgotPasswordConfirm')));
const Page404 = React.lazy(() => retry(() => import('./views/Pages/Page404/Page404')));
const Page500 = React.lazy(() => retry(() => import('./views/Pages/Page500/Page500')));
const ValidateEmail = React.lazy(() => retry( () => import('./views/MyProfile/ValidateEmail')));

class App extends Component {

    render() {
        return (
            <Provider store={this.props.store}>
                { this.props.loading && <Loading defaultLabel={this.props.loading} /> }
                <Router history={history}>
                    <React.Suspense fallback={loading()}>
                        <Switch>
                            <Route exact path="/delete-cache" name="DeleteCache" render={props => <DeleteCache {...props}/>}/>
                            <Route exact path="/force-login/:token/" name="Force Login Page" render={props => <ForceLogin {...props}/>}/>
                            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
                            <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props}/>}/>
                            <Route exact path="/generate-password/:token" name="Generate Password" render={props => <ForgotPasswordConfirm generate={true} {...props}/>}/>
                            <Route exact path="/forgot-password/:token" name="Forgot Password" render={props => <ForgotPasswordConfirm generate={false} {...props}/>}/>
                            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
                            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
                            <Route exact path="/validate-email/:token" name="Bienvenido" render={props => <ValidateEmail {...props}/>}/>
                            <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>}/>
                        </Switch>
                    </React.Suspense>
                </Router>
            </Provider>
        );
    }
}

App.propTypes = {
    store: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => (
    {
        loading: state.loading.loading
    }
);


export default connect(mapStateToProps)(App);
