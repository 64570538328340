import {Constants} from "../constants/constants";
import {getToken, setToken} from "../services/Auth";
import * as Sentry from '@sentry/browser';
import {doRefresh} from "../serviceWorker";

export const initialState = {
    token: getToken(),
    user: null
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case Constants.AUTH_LOGOUT:
            localStorage.removeItem('token');
            doRefresh();
            return { ...state, token: null }

        case Constants.AUTH_LOGIN:
            setToken(action.token);
            return { ...state, token: action.token }

        case Constants.AUTH_SET_USER:
            Sentry.configureScope((scope) => {
                scope.setUser({"email": action.user.email});
            });
            window.dataLayer.push({'event': 'SetUser', 'id':action.user.id});
            return { ...state, user: action.user }

        default:
            return state
    }
}
