import React, {useEffect, useState} from "react";
import "./Loading.scss";
import PropTypes from "prop-types";

export const Loading = ({ defaultLabel = 'Cargando...' }) => {

    const [label, setLabel] = useState(defaultLabel);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLabel("Aguarde un instante más...");
        }, 10000);
        return () => clearTimeout(timeout);
    });


    return (
        <div className="loading">
            <div className={"overlay"}/>
            <span className={"label"}>{label}</span>
        </div>
    )
}

Loading.propTypes = {
    defaultLabel: PropTypes.string
};