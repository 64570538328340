export const Constants = {
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    AUTH_LOGIN: 'AUTH_LOGIN',
    AUTH_SET_USER: 'AUTH_SET_USER',
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    REFRESH: 'REFRESH',
    ERROR_500: 'ERROR_500',
    ERROR_NETWORK: 'ERROR_NETWORK',
    SHOW_ACTIVE_ID: 2,
    MESSAGE_SHOW: 'MESSAGE_SHOW',
    MESSAGE_ANSWER: 'MESSAGE_ANSWER',
    WEB_CHECKOUT_UPDATE: 'WEB_CHECKOUT_UPDATE',
};
