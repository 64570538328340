import { combineReducers }  from 'redux';
import auth from "./auth";
import loading from "./loading";
import message from "./message";
import webCheckout from "./webCheckout";

export default combineReducers({
    auth,
    loading,
    message,
    webCheckout
})


