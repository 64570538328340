import * as Sentry from "@sentry/browser";

const scroll = (element) => {
    element.scrollIntoView(true);
    if(document.querySelector("body").classList.contains('modal-open')) {
        document.querySelector(".modal").scrollBy(0, -20);
    } else {
        window.scrollBy(0, -85);
    }
};

export const scrollToElement = (event) => {
    const element = event.target;

    if(element.getBoundingClientRect().top > (window.screen.availHeight / 3)) {
        setTimeout(() => {
            scroll(element);
        }, 300);
    }
}

export const hasCamera = async () => {
    let md = navigator.mediaDevices;
    if (md && md.enumerateDevices){
        let devices = await md.enumerateDevices();
        if(devices.some(device => 'videoinput' === device.kind)){
            return true;
        }
    }
    return false;

};

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {

                Sentry.addBreadcrumb({
                    category: 'retry',
                    message: retriesLeft.toString(),
                    level: Sentry.Severity.Info
                });
                Sentry.captureException(error);

                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
};
