import {Constants} from "../constants/constants";

export const initialState = {
    loading: false,
    refresh: false,
    error: false
};

export default function loading(state = initialState, action) {
    switch (action.type) {
        case Constants.LOADING:
            return { ...state, loading: action.loading }
        case Constants.ERROR:
            return { ...state, loading: false, error: action.error }
      case Constants.REFRESH:
        return { ...state, refresh: true }

        default:
            return state
    }
}
