import {Constants} from "../constants/constants";

export function loading(text) {
    return { type: Constants.LOADING, loading: text }
}
export function ready() {
    return { type: Constants.LOADING, loading: false }
}
export function error(type) {
    return { type: Constants.ERROR, error: type }
}
export function refresh() {
  return { type: Constants.REFRESH }
}
