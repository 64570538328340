import config from '../config/config';
import {getToken, logout} from "./Auth";
import {ValidationMessage} from "../views/General/ValidationMessage";
import store from '../store/store';
import {loading, ready, error} from "../actions/loading";
import {Constants} from "../constants/constants";
import * as Sentry from '@sentry/browser';

const getHeaders = () => {
    let headers = {
        'Content-Type': 'application/json'
    };

    const token = getToken();

    if(token) {
        headers['X-AUTH-TOKEN'] = token;
    }

    return headers;
};

const buildQueryParams = (queryParams) => {
    if (Object.keys(queryParams).length === 0) {
        return '';
    } else {
        let queryParamsString = [];
        Object.keys(queryParams).forEach(function (key, index) {
            queryParamsString.push(key + '=' + queryParams[key]);
        });
        return '?' + queryParamsString.join('&');
    }
}

const myFetch = (url, options, showLoading, loadingText, format = 'json') => {
    if(showLoading) {
        store.dispatch(loading(loadingText));
    }

    return fetch(url, options)
        .then(res => {
            if(showLoading) {
                store.dispatch(ready());
            }
            if(res.status === 401) {
                logout();
            }
            if(res.status === 403) {
                throw new Error('error403');
            }
            if(res.status === 500) {
                throw new Error('error500');
            }
            if(format === 'blob'){
                return res.blob();
            }
            return res.json()
        })
        .then(res => {
            if(format === 'blob'){
                return res;
            }

            if(res.result) {
                return res.data;
            }
            let error = new Error("validation");
            error.errors = res.errors;
            throw error;
        })
        .catch(res => {
            if(res.message === 'validation'){
                Sentry.addBreadcrumb({
                    category: 'Network',
                    message: 'validation',
                    level: Sentry.Severity.Info
                });
                throw res.errors;
            }else if(res.message === 'error500') {
                Sentry.addBreadcrumb({
                    category: 'Network',
                    message: 'error500',
                    level: Sentry.Severity.Info
                });
                Sentry.captureException(res);
                ValidationMessage({ status: ['Error al comunicarse con el servidor, intentelo nuevamente'] })
                store.dispatch(error(Constants.ERROR_500));
                throw Error;
            }else if(res.message === 'error403') {
                Sentry.addBreadcrumb({
                    category: 'Network',
                    message: 'error403',
                    level: Sentry.Severity.Info
                });
                Sentry.captureException(res);
                ValidationMessage({ status: ['Usted no tiene permisos para realizar esta operación'] })
                throw Error;
            } else {
                Sentry.addBreadcrumb({
                    category: 'Network',
                    message: 'InElse',
                    level: Sentry.Severity.Info
                });
                Sentry.captureException(res);
                store.dispatch(error(Constants.ERROR_NETWORK));
                throw Error;
            }

        });
}

export const download = (url, query = {}, options = {}, filename) => {
    const { showLoading, loadingText } = Object.assign({ loadingText: 'Cargando...', showLoading: true  }, options);
    let data = myFetch(config.Api.Endpoint + url + buildQueryParams(query), {
        headers: getHeaders(),
        mode: 'cors'
    }, showLoading, loadingText, 'blob');

    data.then((obj) =>{
        let dataUrl = window.URL.createObjectURL(obj);
        let a = document.createElement('a');
        a.href = dataUrl;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
    });


};

export const get = (url, query = {}, options = {}) => {
    const { showLoading, loadingText } = Object.assign({ loadingText: 'Cargando...', showLoading: true  }, options);
    return myFetch(config.Api.Endpoint + url + buildQueryParams(query), {
        headers: getHeaders(),
        mode: 'cors'
    }, showLoading, loadingText);
};

export const post = (url, data, options = {}) => {
    const { showLoading, loadingText } = Object.assign({ loadingText: 'Guardando...', showLoading: true  }, options);

    return myFetch(config.Api.Endpoint + url, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
        mode: 'cors'
    }, showLoading, loadingText);
};

export const del = (url, data, options = {}) => {
    const { showLoading, loadingText } = Object.assign({ loadingText: 'Eliminando...', showLoading: true  }, options);
    return myFetch(config.Api.Endpoint + url, {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify(data),
        mode: 'cors'
    }, showLoading, loadingText);
};
