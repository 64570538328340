import * as toastr from "toastr";

export const ValidationMessage = (errors, options) => {
    const defaultOptions = {progressBar: true, preventDuplicates: true, closeButton: true};
    options = Object.assign({}, defaultOptions, options)
    let messages = [];
    for(let key of Object.keys(errors)) {
        try{
            messages.push(...errors[key]);
        } catch (e) {
            messages.push("Error Inesperado");
        }

    }
    toastr.error(messages.join('<br/>'), 'Error!', options)
}
